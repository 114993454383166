import GoogleReviewsArea, {
  ButtonGroup,
  ContentWrapper
} from "./googleReviews.style"

import Button from "../../components/Button"
import Container from "../../components/UI/Container"
import Fade from "react-reveal/Fade"
import Heading from "../../components/Heading"
import React from "react"
import Stars from "../../common/assets/image/elegant/stars.svg"
import Text from "../../components/Text"

const GoogleReviews = () => {
  const staticReviews = [
    {
      node: {
        text:
          "I've had 3 sessions so far with Shikha for HiFu treatments on my legs. At my consultation Shikha took me through all the treatment options, and the results/side effects. She gave me her professional and personal opinion as she has used all the treatments on herself. I chose HiFu as it has no down time and continues to work for months after the treatment.Shikha talks you through each step of the process, and makes sure you're doing OK throughout.  The intensity of the treatment can be tailored to you to make sure you're comfortable. I 100% recommend her.",
        profile_photo_url:
          "https://lh3.googleusercontent.com/a-/AD5-WClta3RGS2iUJFjzUO6zrZwQ2D7DY3Smuj7TTBRl=w120-h120-p-rp-mo-br100",
        author_name: "Charlotte Harris",
      },
    },
    {
      node: {
        text:
          "I had my consultation and facial filler with Shikha. She is so welcoming and just an all around lovely woman. She makes you feel so comfortable and goes at your own pace. She really listens to what you want to achieve and explains everything thoroughly. I highly recommend Shikha for facial aesthetics, absolutely amazing person and so happy with my results. So glad I found her.",
        profile_photo_url:
          "https://lh3.googleusercontent.com/a-/AD5-WCmXsu6mDjE7u3T8hhsxHkebZeTEiowl00hf-5myx6E=w120-h120-p-rp-mo-br100",
        author_name: "Katie Prince",
      },
    },
    {
      node: {
        text:
          "After ringing about 5 different places looking for a yellow fever vaccine at last minute during a national shortage to no avail, Elegant Clinic was recommended. I was able to get a same day appointment with Shikha who was so lovely, professional, and informative - will definitely recommend her to anyone else needing travel vaccines in a pinch! Thank you :)",
        profile_photo_url:
          "https://lh3.googleusercontent.com/a/AEdFTp4U8_MC259tL8xQzJfxQD5gqaspLpMY8Q126BND=w120-h120-p-rp-mo-br100",
        author_name: "Hannah Siuda",
      },
    },
    {
      node: {
        text:
          "Fantastic clinic, all staff very professional. Very happy with my lips and Botox.",
        profile_photo_url:
          "https://lh4.googleusercontent.com/-8_YKw2e2RMM/AAAAAAAAAAI/AAAAAAAAAAA/AMZuucmKnOiJJS-Od9P9NwcyD_Y-rNda0Q/s128-c0x00000000-cc-rp-mo/photo.jpg",
        author_name: "Sophia Harris",
      },
    },
    {
      node: {
        text:
          "I have had my lip fillers and perfect peal with Shikha. She is  exceptionally knowledgeable and talented. I am very happy with her work and would highly recommend.",
        profile_photo_url:
          "https://lh3.googleusercontent.com/-hhED3V2aAVM/AAAAAAAAAAI/AAAAAAAAAAA/AMZuuckwy1rjbdL7PjfLfYan62FLUl9dmw/s128-c0x00000000-cc-rp-mo/photo.jpg",
        author_name: "Akvile Kumar",
      },
    },
  ]

  function truncate(str, no_words) {
    return `${str.split(" ").splice(0, no_words).join(" ")}...`
  }
  return (
    <GoogleReviewsArea>
      <Container width="1340px">
        <ContentWrapper>
          <Fade up delay={300}>
            <Heading content="Client Google Reviews" />
          </Fade>
          <Fade up delay={300}>
            <div className="reviews_container">
              {staticReviews.slice(0, 3).map((review, index) => {
                return (
                  <div key={index} className="review">
                    <img
                      src={review.node.profile_photo_url}
                      alt=""
                      className="profile_photo"
                    />
                    <Text content={review.node.author_name} className="name" />
                    <img src={Stars} alt="stars" className="stars" />
                    <Text
                      content={truncate(review.node.text, 40)}
                      className="review_text"
                    />
                  </div>
                )
              })}
            </div>
          </Fade>
          <Fade up delay={200}>
            <ButtonGroup>
              <a
                href="https://www.google.com/search?gs_ssp=eJzj4tVP1zc0TLPIqMoprjIzYLRSNagwsTA3TjS0NDezTDRMsUw2tDKoSDUyNUsxsDQztjRKMTcxTvMSTc1JTU_MK1FISk0sLalUSM7JzMtMBgBIrhb4&q=elegant+beauty+clinic&oq=elagant+beaty+clini&aqs=chrome.1.69i57j46i13i175i199j0i22i30l7.6979j0j1&sourceid=chrome&ie=UTF-8#lrd=0x4873a19769a1d9c1:0xe256d096392d743f,1,,,"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button title="See all our Google reviews" />
              </a>
            </ButtonGroup>
          </Fade>
        </ContentWrapper>
      </Container>
    </GoogleReviewsArea>
  )
}

export default GoogleReviews
