import React from "react"
import { Modal } from "@redq/reuse-modal"
import { graphql, useStaticQuery } from "gatsby"

import Banner from "../containers/Banner"
import ChangeYourLife from "../containers/ChangeYourLife"
import Features from "../containers/Features"
import GoogleReviews from "../containers/GoogleReviews"
import HomeStories from "../containers/HomeStories"
import LatestOffers from "../containers/LatestOffers"
import Layout from "../containers/Layout"
import SEO from "../components/SEO"
import ShikhaSecBanner from "../containers/ShikhaSecBanner"
import Testimonial from "../containers/Testimonial"
import { ContentWrapper } from "../containers/elegant.style"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      # allGooglePlacesReview {
      #   edges {
      #     node {
      #       text
      #       profile_photo_url
      #       author_name
      #     }
      #   }
      # }
      allContentfulLatestOffers {
        edges {
          node {
            title
            text
            priceOff
            thumbnail {
              fluid(quality: 100) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      }
      aesthetics: allContentfulTreatments(
        filter: { treatmentCategory: { eq: "aesthetics" } }
      ) {
        edges {
          node {
            slug
            treatmentTitle
            treatmentCategory
          }
        }
      }
      skin: allContentfulTreatments(
        filter: { treatmentCategory: { eq: "skin" } }
      ) {
        edges {
          node {
            slug
            treatmentTitle
            treatmentCategory
          }
        }
      }
      health: allContentfulTreatments(
        filter: { treatmentCategory: { eq: "health" } }
      ) {
        edges {
          node {
            slug
            treatmentTitle
            treatmentCategory
          }
        }
      }
      slim: allContentfulTreatments(
        filter: { treatmentCategory: { eq: "slim" } }
      ) {
        edges {
          node {
            slug
            treatmentTitle
            treatmentCategory
          }
        }
      }
      elegantJson {
        homeTestimonial {
          text
          name
          image {
            childImageSharp {
              fluid(quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  return (
    <>
      <SEO
        title="Cosmetic Skin Clinic & Aesthetic Treatments in Bournemouth & Poole"
        shortDesc="Elegant Beauty Clinic"
        description="Elegant Aesthetic is a premium provider of medical aesthetic services and anti aging treatments with clinics in Bournemouth, Poole and Southampton. Find out more."
      />
      <Layout home>
        <Modal />
        <ContentWrapper>
          <Banner
            aestheticContentSelect={data.aesthetics}
            healthContentSelect={data.health}
            slimContentSelect={data.slim}
            skinContentSelect={data.skin}
          />
          <Features />
          <Testimonial
            halfBG
            testimonialContent={data.elegantJson.homeTestimonial}
            videoUrl="https://www.youtube.com/embed/V3Dq_RMtmIY?&theme=dark&autohide=2&autoplay=1&modestbranding=1&showinfo=0&showsearch=0&rel=0&iv_load_policy=3&cc_load_policy=1"
          />
          <HomeStories />
          {/* <LatestOffers offers={data.allContentfulLatestOffers} /> */}
          <GoogleReviews reviews={data.allGooglePlacesReview} />
          <ShikhaSecBanner />
          <ChangeYourLife
            whiteBG
            title="Ready to change your life?"
            text="Begin your journey with us today. With Elegant Beauty clinics across the Bournemouth, Poole and Southampton area, we invite you to visit us for a free consultation at your convenience."
          />
        </ContentWrapper>
      </Layout>
    </>
  )
}

export default IndexPage
