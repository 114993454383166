import Fade from "react-reveal/Fade"
import Image from "gatsby-image"
import PropTypes from "prop-types"
import React from "react"
import { closeModal, openModal } from "@redq/reuse-modal"
import { graphql, useStaticQuery } from "gatsby"

import BannerWrapper from "./banner.style"
import Box from "../../components/Box"
import Button from "../../components/Button"
import Container from "../../components/UI/Container"
import Heading from "../../components/Heading"
import RightBar from "./rightBar"
import TreatmentsHomeSelect from "../../containers/treatmentsHomeSelect"
import WatchVideoIcon from "../../common/assets/image/elegant/banner/watch-our-video-main.svg"

const IntroVideo = () => (
  <iframe
    title="ReactivePro"
    width="560"
    height="315"
    src="https://www.youtube.com/embed/4q8mwCFYtcM?&theme=dark&autohide=2&autoplay=1&modestbranding=1&showinfo=0&showsearch=0&rel=0&iv_load_policy=3&cc_load_policy=1"
    frameBorder="0"
    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  />
)

const CloseModalButton = () => (
  <Button
    className="modalCloseBtn"
    variant="fab"
    onClick={() => closeModal()}
    icon={<i className="flaticon-plus-symbol" />}
  />
)

const BannerSection = ({
  row,
  contentArea,
  imageArea,
  motoStyle,
  titleStyle,
  aestheticContentSelect,
  healthContentSelect,
  slimContentSelect,
  skinContentSelect,
}) => {
  const handleVideoModal = () => {
    openModal({
      config: {
        className: "video-modal",
        disableDragging: true,
        default: {
          width: "100%",
          height: "100%",
          x: 0,
          y: 0,
        },
      },
      component: IntroVideo,
      componentProps: {},
      closeComponent: CloseModalButton,
      closeOnClickOutside: false,
    })
  }

  const Data = useStaticQuery(graphql`
    query {
      bannerImage: file(
        relativePath: {
          eq: "image/elegant/banner/sheika-elegegant-hero-image.webp"
        }
      ) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <BannerWrapper>
      <Container mobileGutter fullWidth bg="#F5F5F5" className="container">
        <Box {...row}>
          <Box {...contentArea} className="content_area">
            <Fade up delay={200}>
              <Heading
                content="Don’t just feel beautiful. Feel Elegant."
                {...motoStyle}
              />
            </Fade>
            <Fade up delay={300}>
              <Heading
                content="Start your journey with beauty and confidence today at Elegant Clinic."
                {...titleStyle}
              />
            </Fade>
            <Fade up delay={350}>
              <img
                onClick={handleVideoModal}
                src={WatchVideoIcon}
                alt="testimonial icon"
                className="watchVideo_icon"
              />
            </Fade>
          </Box>
          <Box {...imageArea} className="image_area">
            <Image
              fluid={Data.bannerImage.childImageSharp.fluid}
              alt="Banner"
              className="banner_image"
            />
          </Box>
        </Box>
        <TreatmentsHomeSelect
          aestheticContentSelect={aestheticContentSelect}
          healthContentSelect={healthContentSelect}
          slimContentSelect={slimContentSelect}
          skinContentSelect={skinContentSelect}
        />
      </Container>
      <RightBar offset={0} sectionId="#feature" />
    </BannerWrapper>
  )
}

BannerSection.propTypes = {
  row: PropTypes.object,
  contentArea: PropTypes.object,
  imageArea: PropTypes.object,
  motoStyle: PropTypes.object,
  titleStyle: PropTypes.object,
  aestheticContentSelect: PropTypes.object,
  healthContentSelect: PropTypes.object,
  slimContentSelect: PropTypes.object,
}

BannerSection.defaultProps = {
  row: {
    flexBox: true,
    alignItems: "stretch",
    position: "relative",
  },
  contentArea: {
    width: ["100%", "100%", "100%", "100%", "58%"],

    flexBox: true,
    flexWrap: "wrap",
    justifyContent: "center",
    flexDirection: "column",
    marginTop: ["100%", "30%", "30%", "30%", "0px"],
    position: "relative",
  },
  imageArea: {
    width: ["100%", "100%", "100%", "100%", "59%"],
    flexBox: true,
    alignItems: "flex-end",
  },
  motoStyle: {
    as: "h2",
    color: "#333333",
    fontSize: ["18px"],
    fontWeight: "500",
    mb: ["24px", "32px"],
    letterSpacing: "0.72px",
  },
  titleStyle: {
    as: "h1",
    color: "#333333",
    fontSize: ["32px", "48px"],
    fontWeight: "300",
    letterSpacing: "0.96px",
    lineHeight: "1.3",
  },
}

export default BannerSection
