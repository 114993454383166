import React from "react"
import AnchorLink from "react-anchor-link-smooth-scroll"
import ScrollIcon from "../../common/assets/image/elegant/banner/scroll.svg"

const RightBar = ({ sectionId, offset, onClick, text }) => {
  return (
    <div className="rightbar">
      <AnchorLink
        className="smooth_scroll"
        href={sectionId}
        offset={offset}
        onClick={onClick}
      >
        <img src={ScrollIcon} alt="scroll" className="scroll_icon" />
        <span className="btn_text">{text}</span>
      </AnchorLink>
    </div>
  )
}

export default RightBar
