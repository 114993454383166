import Fade from "react-reveal/Fade"
import React from "react"
import { Link } from "gatsby"

import Button from "../../components/Button"
import Container from "../../components/UI/Container"
import Heading from "../../components/Heading"
import Text from "../../components/Text"
import bannerPhoto from "../../common/assets/image/elegant/banner/Shikha-elegant-beauty-clinic.webp"
import SectionWrapper, {
  Content,
  DetailsInner,
  ImageWrapper,
  MiniHeader,
} from "./shikhaSecBanner.style.js"

const ShikhaSecBanner = () => {
  return (
    <SectionWrapper>
      <Container width="1280px" className="container_wrapper">
        <Fade up delay={100}>
          <DetailsInner>
            <Content>
              <Fade up delay={200}>
                <MiniHeader>
                  <Text content="About Shikha" className="about_header" />
                  <span className="seperator">———</span>
                </MiniHeader>
              </Fade>
              <Fade up delay={400}>
                <Heading as="h3" content="Meet Shikha" />
                <Text content="Shikha is a highly experienced aesthetics practitioner, pharmacist and medical professional. After training for a number of years at the world-renowned Harley Street, London and SkinViva, Manchester, Shikha is passionate about delivering top-quality results in every treatment." />
                <Link to="/about/">
                  <Button
                    title="Learn more about Shikha"
                    className="banner_button"
                  />
                </Link>
              </Fade>
            </Content>
            <ImageWrapper>
              <Fade up delay={100}>
                <img src={bannerPhoto} alt="Shikha Banner" />
              </Fade>
            </ImageWrapper>
          </DetailsInner>
        </Fade>
      </Container>
    </SectionWrapper>
  )
}

export default ShikhaSecBanner
