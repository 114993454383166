import Fade from "react-reveal/Fade"
import Image from "gatsby-image"
import React from "react"
import { Link } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"

import Button from "../../components/Button"
import Container from "../../components/UI/Container"
import Heading from "../../components/Heading"
import Text from "../../components/Text"
import SectionWrapper, {
  ContentWrapper,
  List,
  MiniHeader,
} from "./homeStories.style"

const HomeStories = () => {
  const data = useStaticQuery(graphql`
    query {
      elegantJson {
        homeStories {
          title
          description
          category
          bullets
          buttonText
          url
          image {
            childImageSharp {
              fluid(quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          title
          description
        }
      }
    }
  `)
  const content = data.elegantJson.homeStories

  return (
    <SectionWrapper>
      <Container width="1340px">
        {content.map(
          (
            { title, description, image, buttonText, url, category, bullets },
            index
          ) => {
            const reverse = index % 2 !== 0
            return (
              <ContentWrapper key={index} reverse={reverse} index={index}>
                <div className="image">
                  <Fade up delay={100}>
                    <Image
                      alt="Treatment Stories"
                      fluid={image.childImageSharp.fluid}
                    />
                  </Fade>
                </div>
                <div className="content">
                  <Fade up delay={200}>
                    <MiniHeader>
                      <Text content={category} className="about_header" />
                      <span className="seperator">———</span>
                    </MiniHeader>
                    <Heading content={title} />
                    <Text content={description} />
                    <List>
                      {bullets.map((item, index) => {
                        return <li key={index}>{item}</li>
                      })}
                    </List>
                  </Fade>
                  <Fade up delay={300}>
                    <Link to={`/${url}/`}>
                      <Button title={buttonText} />
                    </Link>
                  </Fade>
                </div>
              </ContentWrapper>
            )
          }
        )}
      </Container>
    </SectionWrapper>
  )
}

export default HomeStories
