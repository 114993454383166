import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const SectionWrapper = styled.section`
  padding: 120px 0 150px 0;
  overflow: hidden;

  @media only screen and (max-width: 1440px) {
    padding: 120px 0;
  }
  @media only screen and (max-width: 1360px) {
    padding: 120px 0;
  }
  @media (max-width: 990px) {
    padding: 80px 0;
  }
  @media (max-width: 767px) {
    padding: 56px 0 56px 0;
  }
  @media (max-width: 480px) {
    padding: 56px 0 56px 0;
  }
`

export const SectionTitle = styled.div`
  text-align: center;
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  h2 {
    font-weight: 700;
    font-size: 26px;
    line-height: 50px;
    @media screen and (max-width: 480px) {
      line-height: 40px;
    }
  }
`

export const FeatureWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media screen and (max-width: 700px) {
    justify-content: space-between;
  }
  .ultimateFeature {
    text-align: center;
    margin-top: 80px;
    width: calc(25% - 60px);
    @media only screen and (max-width: 1440px) {
      margin-top: 50px;
    }
    @media screen and (max-width: 991px) {
      width: 100%;
    }
    @media screen and (max-width: 768px) {
      width: 100%;
    }
    @media screen and (max-width: 624px) {
      width: 100%;
    }
    @media screen and (max-width: 480px) {
      margin: 24px 0;
    }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
      margin-top: 50px;
    }
    .icon__wrapper {
      margin-bottom: 20px;
      display: flex;
      justify-content: center;
      max-height: 300px;
      img {
        width: 100%;
        height: 100%;
        @media screen and (max-width: 480px) {
          height: 80px;
        }
      }
    }
    h3 {
      font-size: 26px;
      font-weight: 100;
      letter-spacing: 1.28px;
      color: ${themeGet("colors.textColor", "#333333")};
    }
    p {
      color: ${themeGet("colors.textColor")};
      @media screen and (max-width: 991px) {
        max-width: 700px;
        text-align: center;
        margin: 0 auto 32px auto;
      }
      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }
  }
`

export default SectionWrapper
