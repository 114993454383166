import Fade from "react-reveal/Fade"
import PropTypes from "prop-types"
import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"

import Button from "../../components/Button"
import Container from "../../components/UI/Container"
import FeatureBlock from "../../components/FeatureBlock"
import Heading from "../../components/Heading"
import Image from "../../components/Image"
import SectionHeading from "../../components/SectionHeading"
import Text from "../../components/Text"
import SectionWrapper, { FeatureWrapper } from "./Feature.style"

const Features = () => {
  const data = useStaticQuery(graphql`
    query {
      elegantJson {
        features {
          id
          title
          desc
          learnMoreLink
          buttonText
          icon {
            childImageSharp {
              fluid(quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)
  const mainFeatures = data.elegantJson.features
  return (
    <SectionWrapper id="feature">
      <Container width="1750px">
        <Fade up delay={100}>
          <SectionHeading
            mb="20px"
            title="Look youthful. Feel happier. Be confident."
          />
        </Fade>

        <FeatureWrapper>
          {mainFeatures.map((feature, index) => (
            <FeatureBlock
              key={index}
              icon={
                <Fade up delay={100}>
                  <Image
                    fluid={
                      (feature.icon !== null) | undefined
                        ? feature.icon.childImageSharp.fluid
                        : {}
                    }
                    src={feature.icon.childImageSharp.fluid.src}
                    alt={`Feature Image ${feature.id}`}
                    objectFit="cover"
                    className="service__image"
                  />
                </Fade>
              }
              title={<Heading as="h3" content={feature.title} />}
              description={
                <React.Fragment>
                  <Fade up delay={200}>
                    <Text content={feature.desc} />
                  </Fade>
                  <Fade up delay={300}>
                    <Link to={`/${feature.learnMoreLink}/`}>
                      <Button title={feature.buttonText} />
                    </Link>
                  </Fade>
                </React.Fragment>
              }
              className="ultimateFeature"
            />
          ))}
        </FeatureWrapper>
      </Container>
    </SectionWrapper>
  )
}

Features.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
}

Features.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: "wrap",
    ml: ["-30px", "-30px", "-30px", "-25px", "-30px"],
    mr: ["-30px", "-30px", "-30px", "-25px", "-45px"],
  },
  col: {
    width: [1, 1 / 2, 1 / 2, 1 / 3],
  },
}

export default Features
