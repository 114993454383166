import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

import AestheticsIcon from "../../common/assets/image/elegant/select/elegant-aesthetics-face.png"
import HeartIcon from "../../common/assets/image/elegant/select/heart.png"
import SkinIcon from "../../common/assets/image/elegant/select/skin.png"
import SlimIcon from "../../common/assets/image/elegant/select/elegant-slim.png"

const TreatmentsSelectWrapper = styled.section`
  position: absolute;
  top: 80%;
  left: 190px;
  z-index: 1;
  @media only screen and (max-width: 1720px) {
    left: 60px;
  }
  @media only screen and (max-width: 1550px) {
    left: 30px;
    top: 82%;
  }
  @media only screen and (max-width: 1330px) {
    top: 85%;
    left: 0px;
  }
  @media only screen and (max-width: 1260px) {
    top: 85%;
    left: -20px;
  }
  @media only screen and (max-width: 1220px) {
    display: none;
  }

  .treatments_select {
    margin: 0 auto;
    display: flex;
    flex-basis: auto;
    justify-content: center;
    width: 100%;
    /* flex-wrap: wrap; */
    box-shadow: 0 0 20px rgba(175, 182, 194, 0.3) !important;
    border-radius: 16px;
  }
`
export const DropdownCheckerAesthetics = styled.div`
  .reusecore__input {
    width: 100%;
  }
  .field-wrapper input {
    min-height: 60px;
    padding: 12px 28px;
    box-shadow: 5px 10px #888888;
  }
  .reusecore__select {
    color: ${themeGet("colors.textColor", "#333333")};
    width: 360px;
    @media only screen and (max-width: 1720px) {
      width: 350px;
    }
    @media only screen and (max-width: 1550px) {
      width: 300px;
    }
    @media only screen and (max-width: 1300px) {
      width: 290px;
    }
  }
  .select__control {
    min-height: 92px;
    border-color: #fbfbf9;
    padding: 0 10px 0 25px;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    &:before {
      display: block;
      content: " ";
      background-image: url(${AestheticsIcon});
      background-size: 38px 56px;
      height: 56px;
      width: 38px;
      margin-right: 8px;
    }

    &:hover {
      border-color: #fbfbf9;
    }
  }
  .select__single-value {
    color: ${themeGet("colors.secondaryTextColor", "#297E7F")};
    font-weight: 500;
  }
`

export const DropdownCheckerSkin = styled.div`
  .reusecore__input {
    width: 100%;
  }
  .field-wrapper input {
    min-height: 60px;
    padding: 12px 28px;
  }
  .reusecore__select {
    color: ${themeGet("colors.textColor", "#333333")};
    width: 360px;
    @media only screen and (max-width: 1720px) {
      width: 350px;
    }
    @media only screen and (max-width: 1550px) {
      width: 300px;
    }

    @media only screen and (max-width: 1300px) {
      width: 290px;
    }
  }
  .select__control {
    min-height: 92px;
    border-color: #fbfbf9;
    padding: 0 10px 0 25px;
    &:before {
      display: block;
      content: " ";
      background-image: url(${SkinIcon});
      background-size: 38px 56px;
      height: 56px;
      width: 38px;
      margin-right: 8px;
    }

    &:hover {
      border-color: #fbfbf9;
    }
  }
  .select__single-value {
    color: ${themeGet("colors.secondaryTextColor", "#297E7F")};
    font-weight: 500;
  }
`
export const DropdownCheckerHealth = styled.div`
  .reusecore__input {
    width: 100%;
  }
  .field-wrapper input {
    min-height: 60px;
    padding: 12px 28px;
  }
  .reusecore__select {
    color: ${themeGet("colors.textColor", "#333333")};
    width: 360px;
    @media only screen and (max-width: 1720px) {
      width: 350px;
    }
    @media only screen and (max-width: 1550px) {
      width: 300px;
    }
    @media only screen and (max-width: 1300px) {
      width: 290px;
    }
  }
  .select__control {
    min-height: 92px;
    border-color: #fbfbf9;
    padding: 0 10px 0 25px;
    &:before {
      display: block;
      content: " ";
      background-image: url(${HeartIcon});
      background-size: 95px 56px;
      height: 56px;
      width: 95px;
      margin-right: 8px;
    }

    &:hover {
      border-color: #fbfbf9;
    }
  }
  .select__single-value {
    color: ${themeGet("colors.secondaryTextColor", "#297E7F")};
    font-weight: 500;
  }
`

export const DropdownCheckerSlim = styled.div`
  .reusecore__input {
    width: 100%;
  }
  .field-wrapper input {
    min-height: 60px;
    padding: 12px 28px;
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
      padding-top: 0;
      padding-bottom: 0;
      min-height: 45px;
    }
  }
  .reusecore__select {
    color: ${themeGet("colors.textColor", "#333333")};
    width: 360px;
    @media only screen and (max-width: 1720px) {
      width: 350px;
    }
    @media only screen and (max-width: 1550px) {
      width: 300px;
    }

    @media only screen and (max-width: 1300px) {
      width: 290px;
    }
  }
  .select__control {
    min-height: 92px;
    border-color: #fbfbf9;
    padding: 0 10px 0 25px;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    &:before {
      display: block;
      content: " ";
      background-image: url(${SlimIcon});
      background-size: 38px 56px;
      height: 56px;
      width: 38px;
      margin-right: 8px;
    }

    &:hover {
      border-color: #fbfbf9;
    }
  }
  .select__single-value {
    color: ${themeGet("colors.secondaryTextColor", "#297E7F")};
    font-weight: 500;
  }
`

export const DropdownControl = styled.div`
  display: flex;
  align-items: center;
`

export default TreatmentsSelectWrapper
