import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const GoogleReviewsArea = styled.div`
  width: 100%;
  overflow: hidden;
  padding: 120px 0 100px;
  @media only screen and (max-device-width: 1280px) {
    padding: 120px 100px;
  }
  @media only screen and (max-width: 480px) {
    padding: 60px 0 20px 0;
  }
`

export const ContentWrapper = styled.div`
  margin: 0 auto;
  text-align: center;

  h2 {
    font-size: 42px;
    font-weight: 500;
    letter-spacing: 1px;
    color: ${themeGet("colors.textColor", "#333333")};

    @media only screen and (max-width: 1366px) {
      line-height: 40px;
      margin-bottom: 20px;
    }
    @media only screen and (max-width: 667px) {
      line-height: 38px;
      margin-bottom: 15px;
      padding: 0 15px;
    }
  }

  p {
    color: ${themeGet("colors.textColor", "#333333")};
    line-height: 28px;
    padding: 0 15px;
  }

  .reviews_container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 80px 80px;
    grid-template-areas: ". . .";
    margin-top: 160px;

    @media only screen and (max-width: 990px) {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;
      grid-template-areas: ". .";
      gap: 140px 80px;
    }
    @media only screen and (max-width: 680px) {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      grid-template-areas: ".";
      gap: 120px 80px;
      margin-top: 110px;
    }
  }

  .review {
    padding: 0 30px 40px;
    background-color: white;
    box-shadow: 0px 0px 50px #0000000f;
    @media only screen and (max-width: 560px) {
      padding: 0 24px 40px;
    }

    .name {
      font-family: "bariol-regular", sans-serif;
      padding: 0px;
      margin-top: 32px;
      font-size: 28px;
      margin-bottom: 24px;
      padding: 0px;
      line-height: 36px;
      @media only screen and (max-width: 560px) {
        font-size: 24px;
        line-height: 26px;
        margin-bottom: 24px;
      }
    }

    .review_text {
      margin-bottom: 0px;
      padding: 0px;
    }

    .priceOff {
      color: ${themeGet("colors.secondaryTextColor")};
      font-size: 36px;
      font-weight: 600;
      margin-bottom: 40px;
      @media only screen and (max-width: 560px) {
        font-size: 36px;
        margin-bottom: 32px;
      }
    }

    .thumbnail {
      margin: -64px auto 0 auto;
      width: 128px;
      border-radius: 50%;
    }

    .reusecore__button {
      margin-top: 32px;
      text-transform: inherit;
      width: 200px;
      color: ${themeGet("colors.white", "#fff")};
      background-color: ${themeGet("colors.secondaryBotton", "#886638")};
      border: 1px solid ${themeGet("colors.secondaryBotton", "#886638")};
      &:hover {
        background-color: #fbfbf9;
        border: 1px solid ${themeGet("colors.secondaryBotton", "#886638")};
        color: ${themeGet("colors.secondaryBotton", "#886638")};
      }
    }
  }
`

export const ButtonGroup = styled.div`
  .reusecore__button {
    margin-top: 80px;
    text-transform: inherit;
    color: ${themeGet("colors.primaryBotton", "#fff")};
    background-color: transparent;
    border: 1px solid ${themeGet("colors.primaryBotton", "#886638")};
    &:hover {
      background-color: ${themeGet("colors.primaryBotton", "#886638")};
      border: 1px solid ${themeGet("colors.primaryBotton", "#886638")};
      color: white;
    }
  }
`

export default GoogleReviewsArea
