import Fade from "react-reveal/Fade"
import PropTypes from "prop-types"
import React from "react"
import { components } from "react-select"
import { navigate } from "gatsby"

import Arrow from "../../common/assets/image/elegant/keyboard_arrow_down.svg"
import Container from "../../components/UI/Container"
import Dropdown from "../../components/Select"
import TreatmentsSelectWrapper, {
  DropdownCheckerAesthetics,
  DropdownCheckerHealth,
  DropdownCheckerSkin,
  DropdownCheckerSlim,
  DropdownControl,
} from "./treatmentsHomeSelect.style"

const TreatmentsHomeSelect = ({
  sectionOptions,
  category,
  treatmentsData,
  aestheticContentSelect,
  healthContentSelect,
  slimContentSelect,
  skinContentSelect,
}) => {
  const options = data => {
    const slimOptions = []
    data.edges.map(({ node }) => {
      return slimOptions.push({ label: node.treatmentTitle, value: node.slug })
    })
    return slimOptions
  }

  const aestheticDefaultValue = [{ label: `Aesthetic treatments` }]
  const skinDefaultValue = [{ label: `Skin treatments` }]
  const healthDefaultValue = [{ label: `Health treatments` }]
  const slimDefaultValue = [{ label: `Slim treatments` }]

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={Arrow} alt="arrow down" />
      </components.DropdownIndicator>
    )
  }

  return (
    <TreatmentsSelectWrapper>
      <Container width="1352px">
        <Fade up delay={100}>
          <div className="treatments_select">
            <DropdownCheckerAesthetics>
              <DropdownControl>
                <Dropdown
                  theme={theme => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#d0eded",
                      primary: "#d0eded",
                      primary50: "#d0eded",
                    },
                  })}
                  isSearchable={false}
                  options={options(aestheticContentSelect)}
                  defaultValue={aestheticDefaultValue[0]}
                  onChange={({ value }) => navigate(`/aesthetics/${value}/`)}
                  components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator,
                  }}
                />
              </DropdownControl>
            </DropdownCheckerAesthetics>
            <DropdownCheckerSkin>
              <DropdownControl>
                <Dropdown
                  theme={theme => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#d0eded",
                      primary: "#d0eded",
                      primary50: "#d0eded",
                    },
                  })}
                  isSearchable={false}
                  options={options(skinContentSelect)}
                  defaultValue={skinDefaultValue[0]}
                  id="health"
                  onChange={({ value }) => navigate(`/skin/${value}/`)}
                  components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator,
                  }}
                />
              </DropdownControl>
            </DropdownCheckerSkin>
            <DropdownCheckerHealth>
              <DropdownControl>
                <Dropdown
                  theme={theme => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#d0eded",
                      primary: "#d0eded",
                      primary50: "#d0eded",
                    },
                  })}
                  isSearchable={false}
                  options={options(healthContentSelect)}
                  defaultValue={healthDefaultValue[0]}
                  id="health"
                  onChange={({ value }) => navigate(`/health/${value}/`)}
                  components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator,
                  }}
                />
              </DropdownControl>
            </DropdownCheckerHealth>
            <DropdownCheckerSlim>
              <DropdownControl>
                <Dropdown
                  theme={theme => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#d0eded",
                      primary: "#d0eded",
                      primary50: "#d0eded",
                    },
                  })}
                  isSearchable={false}
                  options={options(slimContentSelect)}
                  defaultValue={slimDefaultValue[0]}
                  id="slim"
                  onChange={({ value }) => navigate(`/slim/${value}/`)}
                  components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator,
                  }}
                />
              </DropdownControl>
            </DropdownCheckerSlim>
          </div>
        </Fade>
      </Container>
    </TreatmentsSelectWrapper>
  )
}

// PricesAndOffers style props
TreatmentsHomeSelect.propTypes = {
  sectionOptions: PropTypes.object,
}

// PricesAndOffers default style
TreatmentsHomeSelect.defaultProps = {
  // section options default style
  sectionOptions: {
    mx: "auto",
    flexBox: true,
    justifyContent: "space-evenly",
    alignItems: "center",
    bg: "#fff",
    maxWidth: "353px",
    minHeight: "70px",
    borderRadius: "16px",
    boxShadow: "0 0 20px rgba(175, 182, 194, 0.3)",
  },
}

export default TreatmentsHomeSelect
