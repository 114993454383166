import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const SectionWrapper = styled.div`
  padding: 210px 0 0 0;
  background: ${themeGet("colors.white", "#fff")};
  overflow: hidden;
  position: relative;
  @media only screen and (max-width: 1440px) {
    padding: 210px 0 210px;
  }
  @media only screen and (max-width: 1360px) {
    padding: 210px 0 0px;
  }
  @media (max-width: 991px) {
    padding: 80px 0 0px;
  }
  @media (max-width: 767px) {
    padding: 0 0 56px;
  }
  @media only screen and (max-width: 480px) {
    padding: 0 0 16px;
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: ${props => (props.index === 2 ? "190px" : "245px")};
  flex-direction: ${props => (props.reverse ? "row-reverse" : "row")};
  justify-content: space-between;
  &::last-child {
    margin-bottom: 0px;
  }
  @media only screen and (max-width: 1280px) {
    margin-bottom: 254px;
  }
  @media only screen and (max-width: 991px) {
    margin-bottom: 100px;
  }
  @media only screen and (max-width: 768px) {
    margin-bottom: 80px;
  }
  @media only screen and (max-width: 480px) {
    margin-bottom: 40px;
  }
  .image {
    width: 685px;
    @media only screen and (max-width: 1280px) {
      width: calc(100% - 500px);
    }
    @media only screen and (max-width: 991px) {
      width: 100%;
      margin-bottom: 24px;
    }
    @media only screen and (max-width: 768px) {
      width: 100%;
      margin-bottom: 24px;
    }
  }
  .content {
    width: 485px;
    @media only screen and (max-width: 991px) {
      width: 100%;
    }
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
    h2 {
      color: ${themeGet("colors.textColor", "#333333")};
      font-size: 32px;
      line-height: 40px;
      font-weight: 300;
      letter-spacing: 1.28px;
      margin-bottom: 24px;

      @media only screen and (max-width: 1366px) {
        font-size: 30px;
        margin-bottom: 20px;
      }
      @media only screen and (max-width: 991px) {
        font-size: 32px;
      }
      @media only screen and (max-width: 768px) {
        max-width: 100%;
      }
    }
    @media only screen and (max-width: 480px) {
      max-width: 100%;
      text-align: left;
    }
  }
  p {
    font-size: 16px;
    line-height: 28px;
    color: ${themeGet("colors.textColor", "#333333")};
    margin-bottom: 24px;
    @media only screen and (max-width: 1366px) {
      text-align: left;
    }
  }
`

export const MiniHeader = styled.div`
  display: flex;
  p {
    font-size: 16px;
    font-weight: 300;
    font-family: "bariol-regular";
    margin-bottom: 16px;
    margin-right: 8px;
  }
  .seperator {
    letter-spacing: -0.14rem;
    color: ${themeGet("colors.secondaryColor", "#297E7F")};
    margin-top: 3px;
  }
`

export const List = styled.ul`
  font-size: 16px;
  color: ${themeGet("colors.textColor", "#333333")};
  margin-bottom: 32px;

  li {
    color: ${themeGet("colors.textColor", "#333333")};
    font-size: 16px;
    line-height: 28px;
    list-style-type: disc;
    margin-left: 40px;
  }
`

export default SectionWrapper
