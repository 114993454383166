import styled from "styled-components"

import slimFigure from "../../../static/images/elegant-slim.png"

const BannerWrapper = styled.section`
  align-items: center;
  display: flex;
  margin: 0 80px;
  @media only screen and (max-width: 1600px) {
    margin: 0 40px;
  }
  @media only screen and (max-width: 1366px) {
    margin: 0 0;
    padding: 0 20px;
  }

  .content_area {
    padding: 0 0 0 190px;
    @media only screen and (max-width: 1720px) {
      padding: 0 0 0 120px;
    }
    @media only screen and (max-width: 1530px) {
      padding: 0 0 0 86px;
    }
    @media only screen and (max-width: 1300px) {
      padding: 0 0 0 56px;
    }
    @media only screen and (max-width: 991px) {
      padding: 65px 0 80px 0px;
    }
    @media only screen and (max-width: 480px) {
      padding: 0 0 40px 0;
    }


  }

  .rightbar {
    position: absolute;
    transform: rotate(90deg);
    right: -80px;
    bottom: 40px;
    opacity: 0.8;
    @media only screen and (max-width: 1600px) {
    right: -65px;
  }
    @media only screen and (max-width: 1366px) {
    display: none;
  }
  }

  .scroll_icon {
    opacity: 0.5;
    height: 15px;
  }

  .container {
    position: relative;
     height: 87vh;
     @media (max-width: 1600px) {
     height: 80vh;
    }
    @media only screen and (max-width: 1366px) {
    height: 82vh;
    }
    @media only screen and (max-width: 991px) {
    height: 93vh;
    }
    @media only screen and (max-width: 1220px) {
      background-image: url('${slimFigure}');
      background-repeat: no-repeat;
      background-position: top;
    }
    @media (min-width: 2120px) {
      height: 87vh;
    }
  }

  .watchVideo_icon {
    width: 200px;
    cursor: pointer;
    margin-top: 32px;
    @media only screen and (max-width: 480px) {
      margin-top: 24px;
    }
  }
  .banner_image {
    width: 100%;
    margin-left: auto;
  }
  .image_area {
    min-width: 600px;
    height: 750px;
    @media (max-width: 1600px) {
     height: 80vh;
    }
    @media only screen and (max-width: 1366px) {
    height: 82vh;
    }
    @media (max-width: 1220px) {
      display: none;
    }
    @media (min-width: 2120px) {
      height: 692px;
    }
  }
`

export default BannerWrapper
